.eye_container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: calc(100vw / 40);
}

.eye_container .eye {
  position: relative;
  border-radius: 50%;
  height: calc(100vw / 20);
  width: calc(100vw / 20);
  background: #fff;
  box-shadow: inset 0 0 calc(100vw / 60) rgba(var(--primaryColor));
  transition: scale 0.5s, background-color 0.5s, box-shadow 0.5s;
}

.eye_container .eye::after {
  content: " ";
  position: absolute;
  bottom: 5%;
  right: 50%;
  width: 20%;
  height: 20%;
  background: rgba(var(--primaryColor));
  border-radius: 50%;
  transition: 0.5s;
}
