:root {
  --primaryColor: 100, 21, 255;
  --secondaryColor: 254 237 244;
}

::-webkit-scrollbar-track {
  border: 1px solid rgba(var(--primaryColor));
  background-color: rgba(var(--secondaryColor));
}

::-webkit-scrollbar {
  width: 10px;
  background-color: rgba(var(--secondaryColor));
}

::-webkit-scrollbar-thumb {
  background-color: rgba(var(--primaryColor));
}

.content-full {
  overflow: overlay;
  min-height: calc(100vh - 250px);
}
